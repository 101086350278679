import { useStore } from "App/hooks-store/store";
import CreatorCard from "./Components";
import { useCallback, useEffect, useReducer, useState } from "react";
import './ProgressBar.scss';
import closeIcon from "Assets/Icons/close.svg";
const initialState = {
    queue: [],
    originalQueue: [],
    completedQ: [],
    currentTask: null,
    totalTasks: 0, 
    completedTasks: 0, 
    failedTasks: 0, 
    duplicateSkipped: 0, 
};

const screenshotQueueReducer = (state, action) => {
    switch (action.type) {
        case "ADD_TO_QUEUE":
            const isTaskInQueue = state.originalQueue.some(task => task.id === action.payload?.id);
            if(isTaskInQueue) {
                return {...state, duplicateSkipped: state.duplicateSkipped + 1};
            }
            return {
                ...state,
                queue: [...state.queue, action.payload],
                originalQueue: [...state.originalQueue, action.payload],
                totalTasks: state.totalTasks + 1,
            };

        case "PROCESS_NEXT_TASK":
            const [nextTask, ...rest] = state.queue;
            return {
                ...state,
                queue: rest,
                currentTask: nextTask || null,
            };

        case "TASK_PROGRESS":
            let progressCount = Number(parseFloat(action.payload).toFixed(2)) + state.completedTasks;
            return {
                ...state,
                completedTasks: Number(parseFloat(progressCount).toFixed(2))
            };
        case "TASK_COMPLETED":
            let tasksCount  = Number(parseFloat(action.payload).toFixed(2)) + state.completedTasks
            return {
                ...state,
                currentTask: null,
                completedQ: [...state?.completedQ, state.currentTask],
                completedTasks: Number(parseFloat(tasksCount).toFixed(2)),
            };
        case "TASK_FAILED":
            let failCount  = action.type === 'TASK_FAILED' ? Number(parseFloat(action.payload).toFixed(2)) + state.failedTasks : state.failedTasks
            return {
                ...state,
                currentTask: null,
                failedTasks: Number(parseFloat(failCount).toFixed(2)),
            };

        case "RESET_PROGRESS":
            return {
                ...state,
                totalTasks: 0,
                completedTasks: 0,
            };

        default:
            return initialState;
    }
};

const ProgressiveCreatorScreenShot = () => {
    const [{communityThumbnailData}, dispatch] = useStore();
    const [updatingProgress, dispatchProgress] = useReducer(screenshotQueueReducer, initialState)


    useEffect(() => {
        if (communityThumbnailData?.length > 0) {
            communityThumbnailData.forEach((data) =>
                dispatchProgress({ type: "ADD_TO_QUEUE", payload: data })
            );
        }
    }, [communityThumbnailData]);
    
    const onClose = (e) => {
        e.preventDefault();
        dispatch('setCommunityThumbnailData', null)
        dispatchProgress({ type: ""});
    }
    useEffect(() => {
        if (!updatingProgress.currentTask && updatingProgress.queue.length > 0) {
            dispatchProgress({ type: "PROCESS_NEXT_TASK" });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updatingProgress]);
    useEffect(() => {
    }, [updatingProgress?.completedTasks, updatingProgress?.failedTasks, updatingProgress?.totalTasks]);

    const updateProgress = useCallback((state) => {
        dispatchProgress({ type: "TASK_PROGRESS", payload: Number(parseFloat(state).toFixed(2)) });
    }, [])
    const updateCompleteTask = useCallback((state) => {
        dispatchProgress({ type: "TASK_COMPLETED", payload: Number(parseFloat(state).toFixed(2)) });
    }, [])
    const updateFailed = useCallback((state) => {
        dispatchProgress({ type: "TASK_FAILED", payload: Number(parseFloat(state).toFixed(2)) });
    }, [])
    useEffect(() => {
        if(!!communityThumbnailData) {
            window.addEventListener("beforeunload", alertUser);
            return () => {
              window.removeEventListener("beforeunload", alertUser);
            };
        }
       
      }, [communityThumbnailData]);
    const alertUser = (e) => {
        e.preventDefault();
    };
    return (
        <div className={`${!communityThumbnailData && 'hidden'}`}>
        {updatingProgress.currentTask && <CreatorCard {...updatingProgress.currentTask} onCaptureProgress={updateProgress} onProgressComplete={updateCompleteTask} onCaptureError={updateFailed} />}
        {updatingProgress?.totalTasks && <ProgressBar 
            completion={Math.round((updatingProgress?.completedTasks / updatingProgress?.totalTasks) * 100)} 
            totalTasks={updatingProgress?.totalTasks}
            completedTasks={updatingProgress?.completedTasks} 
            failedTasks={updatingProgress?.failedTasks} 
            duplicateSkipped={updatingProgress?.duplicateSkipped} 
            onClose={onClose}
        />}
        </div>
    )
}

export default ProgressiveCreatorScreenShot;

export const ProgressBar = ({ completion, totalTasks, completedTasks, failedTasks, duplicateSkipped, onClose }) => {
    // Ensure completion is between 0 and 100
    const percentage = Math.min(Math.max(completion, 0), 100);
    const radius = 50; // Radius of the circle
  const circumference = 2 * Math.PI * radius;

  // Calculate stroke-dashoffset based on percentage
  const offset = circumference - (percentage / 100) * circumference;
    const [show, setShow] = useState(false)
    return (
      <div className="progress-bar-wrapper">
        {!show && <div className="progress-state cursor-pointer" onClick={()=> setShow(true)}>{percentage}%</div>}
        {show && 
            <div className="progress-details-container" onClick={()=> setShow(false)}>
                {percentage > 99 && <div onClick={onClose} className="flex justify-end cursor-pointer"><img src={closeIcon} alt="close" /></div>}
                <table>
                    <tbody>
                        <tr>
                            <th>Total Tasks</th>
                            <td>{totalTasks}</td>
                        </tr>
                        <tr>
                            <th>Completed</th>
                            <td>{completedTasks}</td>
                        </tr>
                        <tr>
                            <th>In queue</th>
                            <td>{Number(parseFloat(totalTasks - completedTasks)?.toFixed(2))}</td>
                        </tr>
                        <tr>
                            <th>Error</th>
                            <td>{failedTasks}</td>
                        </tr>
                        {/* <tr>
                            <th>Duplicate</th>
                            <td>{duplicateSkipped}</td>
                        </tr> */}
                    </tbody>
                </table>
                <div className="circular-progress-bar">
                    <svg
                        className="circular-svg"
                        width="120"
                        height="120"
                        viewBox="0 0 120 120"
                    >
                        {/* Background Circle */}
                        <circle
                        className="circular-bg"
                        cx="60"
                        cy="60"
                        r={radius}
                        fill="none"
                        stroke="#e0e0df"
                        strokeWidth="10"
                        />
                        {/* Foreground Circle */}
                        <circle
                        className="circular-fg"
                        cx="60"
                        cy="60"
                        r={radius}
                        fill="none"
                        stroke="#3b82f6"
                        strokeWidth="10"
                        strokeDasharray={circumference}
                        strokeDashoffset={offset}
                        />
                    </svg>
                    <div className="circular-percentage">{`${percentage}%`}</div>
                </div>
            </div>
        }        
    </div>
    );
  };
