import html2canvas from "html2canvas";
export const Html2Canvas = ({input = null, callback, backgroundColor = null, scale = 1, cloneId = '', onCaptureProgress, onProgressComplete, onCaptureError}) => {
    try {
        if(input) {
            onCaptureProgress(0.20)
            html2canvas(input, {
                logging: true,
                allowTaint: false,
                useCORS: true,
                backgroundColor,
                scale,
                onclone: ((clonedDoc) => {
                    onCaptureProgress(0.20)
                    if(cloneId)
                        clonedDoc.getElementById(cloneId).style.visibility = 'visible';
                })
            }).then((canvas) => {
                onCaptureProgress(0.20)
                canvas.toBlob(async blob => {
                    onCaptureProgress(0.20)
                    callback(blob);
                });
            }).catch(error => {
                onCaptureError(1)
                onProgressComplete(0.60)
                console.error('error on taking screen shot: inside', error);
            })
        }
    } catch (error) {
        console.error('error on taking screen shot', error);
    }    
}