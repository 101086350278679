/* eslint-disable react-hooks/exhaustive-deps */
import ButtonAction from "App/Components/UI/ButtonAction";
import ConfirmPopup from "App/Components/UI/ConfirmPopup";
import Image from "App/Components/UI/Image";
import Pagination from "App/Components/UI/Pagination";
import SearchFilter from "App/Components/UI/SearchFilter";
import SocialMedia from "App/Components/UI/SocialMedia";
import Status from "App/Components/UI/Status";
import Table from "App/Components/UI/Table";
import TableHeader from "App/Components/UI/Table/Components/TableHeader";
import tableSort from "App/helpers/tableSort";
import { useCallback, useEffect, useState } from "react";
import MenuPen from "Assets/Images/menu-pen.svg";
import MenuLink from "Assets/Images/menu-link.svg";
import filter from "Assets/Images/filter-icon.svg";
import { useNavigate, useLocation } from "react-router-dom";
import ActionButton from "Assets/Images/ActionButton.svg";
import "./Table.scss";
import Button from "App/Components/UI/Button";
import Popup from "App/Components/UI/Popup";
import Upload from "../Upload";
import CollectorCollectionSearch from "../CollectorCollectionSearch";
import { categorySort, copyClipHandler } from "App/helpers/utilities";
import { useStore } from "App/hooks-store/store";
import useHttp from "App/hooks/use-http";
import ToggleSwitch from "App/Components/UI/ToggleSwitch";
import MultiFilter from "App/Components/UI/MultiFilter";
import ModalOuter from "App/Components/UI/ModalOuter";
import EditCollection from "../../Pages/EditCollection";
import copyIcon from "Assets/Icons/copy_icon.svg";

const actionBlockMenu = [
  {
    label: "Edit",
    action: "edit",
    src: MenuPen,
  },
  {
    label: "Block",
    action: "block",
    src: MenuLink,
  },

];
const actionMenu = [
  {
    label: "Edit",
    action: "edit",
    src: MenuPen,
  },
  {
    label: "Activate",
    action: "activate",
    src: MenuLink,
  },
];
const activeMenu = [
  {
    label: "Edit",
    action: "edit",
    src: MenuPen,
  },
  {
    label: "Revoke",
    action: "unlinkCreator",
    src: MenuLink,
  },
];

const actionBlockActivateMenu = [
  {
    label: "Edit",
    action: "edit",
    src: MenuPen,
  },
  {
    label: "Mark as Reviewed",
    action: "reviewed",
    src: MenuLink,
  },
  {
    label: "Block",
    action: "block",
    src: MenuLink,
  }
];
const actionVerifyBlockMenu = [
  {
    label: "Edit",
    action: "edit",
    src: MenuPen,
  },
  {
    label: "Approve",
    action: "verify",
    src: MenuLink,
  },
  {
    label: "Reject",
    action: "reject",
    src: MenuLink,
  },
];


const CollectionListing = ({
  sort,
  setSort,
  activateHandler,
  page,
  showActivatePop,
  selectedData,
  totalPage,
  paginationConfig,
  setFilteredData,
  filteredData,
  setShowActivatePop,
  setSelectedData,
  setPage,
  list,
  type,
  onSearchChange,
  isTemporary,
  handleFilter,
  toggleSwitch=()=>{},
  toggleSwitchOpenSource=()=>{},
  getCollection=()=>{},
  onRefetchCollectionList=()=>{},
  toggleSwitchIsCollaborative=()=>{},
  collectorsCollectionsExport=()=>{},

}) => {
  const navigate = useNavigate();
  const location = useLocation()
  const [actionStatus, setActionStatus] = useState("");
  const [categoryOptions,setCategoryOptions] = useState([])
  const [showBulkUploadPopup, setBulkUploadPopup] = useState({
    mode: false,
  });
  const [showCollectorCollectionSearchPopup, setCollectorCollectionSearchPopup] = useState({
    mode: false,
  });
  const [openEditPopup, setOpenEditPopup] = useState(false)
  const [openVerifyPopup, setOpenVerifyPopup] = useState(false)
  const dispatch = useStore(false)[1];
  const { triggerAPI } = useHttp();
  const [collectionMenu, setCollectionMenu] = useState([
    {
      label: "Community",
      action: "community",
      selected: type !=='new',
      multiSelect: true,
      type: "displayedFrames",
      sort: true,
      sortKey: "communityName",
    },
    {
      label: "Wallet Address",
      action: "walletAddress",
      selected: type ==='new',
      multiSelect: true,
      type: "displayedFrames",
      sort: true,
      // sortKey: "communityName",
    },
    {
      label: "Collector",
      action: "collector",
      selected: type ==='new',
      multiSelect: true,
      type: "displayedFrames",
      sort: true,
      // sortKey: "communityName",
    },
    {
      label: "Note",
      action: "note",
      selected: type === 'collectorCollection',
      multiSelect: true,
      type: "displayedFrames",
    },
    {
      label: "Category",
      action: "category",
      selected: true,
      multiSelect: true,
      type: "displayedFrames",
    },
    {
      label: "Items",
      action: "items",
      sort: true,
      sortKey: "assetCount",
      selected: true,
      multiSelect: true,
      type: "displayedFrames",
    },
    {
      label: "Owners",
      action: "owners",
      sort: true,
      sortKey: "ownerCount",
      selected: true,
      multiSelect: true,
      type: "displayedFrames",
    },
    {
      label: "Collectors",
      action: "collectors",
      sort: true,
      sortKey: "collectors",
      selected: true,
      multiSelect: true,
      type: "displayedFrames",
    },
    {
      label: "Collection IDs",
      action: "collectionId",
      sort: true,
      sortKey: "collectionId",
      multiSelect: true,
      selected: false,
      type: "displayedFrames",
    },
    {
      label: "Posts",
      action: "announcements",
      selected: false,
      multiSelect: true,
      type: "displayedFrames",
    },
    // {
    //   label: "Upcoming Events",
    //   action: "events",
    //   selected: false,
    //   multiSelect: true,
    //   type: "displayedFrames",
    // },
    {
      label: "Description",
      action: "description",
      selected: false,
      multiSelect: true,
      type: "displayedFrames",
    },
    {
      label: "About Us",
      action: "aboutus",
      selected: false,
      multiSelect: true,
      type: "displayedFrames",
    },
    {
      label: "Social Links",
      action: "sociallink",
      selected: false,
      multiSelect: true,
      type: "displayedFrames",
    },
    {
      label: "Blockchain",
      action: "blockchain",
      selected: false,
      multiSelect: true,
      type: "displayedFrames",
    },
    {
      label: "Featured",
      action: "featured",
      selected: type === 'collection',
      sort: type === 'collection',
      sortKey: "showOnHomePageDisplay",
      multiSelect: true,
      type: "statusToggle",
    },
    {
      label: "Collaborative",
      action: "collaborative",
      selected: type === 'collection',
      sort: type === 'collection',
      sortKey: "isCollaborative",
      multiSelect: true,
      type: "statusToggle",
    },
    {
      label: "Open Source",
      action: "openSource",
      selected: type === 'collection',
      sort: type === 'collection',
      sortKey: "isOpenSource",
      multiSelect: true,
      type: "statusToggle",
    },
    {
      label: "Created By",
      action: "createdBy",
      selected: (type !== 'collectorCollection' && type !=='new'),
      multiSelect: true,
      sort: (type !== 'collectorCollection' && type !=='new'),
      sortKey: "createdBy",
      type: "displayedFrames",
    },
    {
      label: "Reviewed By",
      action: "reviewedBy",
      selected: (type !== 'collectorCollection' && type !=='new'),
      multiSelect: true,
      type: "displayedFrames",
    },
    {
      label: "Verified By",
      action: "verifiedBy",
      selected: type === 'verified',
      multiSelect: true,
      type: "displayedFrames",
    },
    {
      label: "Created At",
      action: "createdAt",
      selected: true,
      multiSelect: true,
      sort: true,
      sortKey: "createdAt",
      type: "displayedFrames",
    },


    {
      label: "Blockchain IDs",
      action: "blockchainId",
      selected: false,
      multiSelect: true,
      type: "displayedFrames",
    },
  
  ]);
  
  const onSortHandler = useCallback(
    (sortKey) => {
      const dir = sort.sortKey === sortKey ? sort.direction : "";
      const direction = tableSort(dir);
      setSort({ direction, sortKey });
    },
    [sort]
  );
  const onCloseActivate = (data) => {
    if (data) {
      activateHandler(actionStatus);
    } else {
      setShowActivatePop({ mode: false });
    }
  };
  const onPageChangeHandler = (page) => {
    setPage(page);
            const root = document.getElementById('content');
        root.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
  };
  const actionHandler = (e) => {
    setSelectedData(e.row);
    setActionStatus(e?.action);
    switch (e?.action) {
      case "edit":
        setOpenEditPopup(true)
        navigate(`?${e?.row?.id}`, { state: { temporary: isTemporary } });
        // navigate(`details/${e?.row?.id}`, { state: { temporary: isTemporary } });
        return;
      case "verify":
        setOpenVerifyPopup(true)
        navigate(`/dashboard/collection-management/verify-collections?${e?.row?.id}`, { state: { temporary: isTemporary } });
        // navigate(`/dashboard/collection-management/verify-collections/verification/${e?.row?.id}`, { state: { temporary: isTemporary } });
        return;
      case "block":
      case "reject":
      case "activate":
      case 'reviewed':
      case 'unlinkCreator':

        return setShowActivatePop({ mode: true });
      default:
        return;
    }
  };
  const stopHandler = (e) => {
    e.stopPropagation();
  };
  const onFilterData = (e) => {
    setFilteredData(e);
  };
  const apiResult = (res) => {
    const { message } = res;
    dispatch("hideSpinner");
    getCollection()
    dispatch("showToast", { toast: { toastMode: "success", message } });
    if (showBulkUploadPopup?.mode) setBulkUploadPopup({ mode: false });
  };
  const submitBulkUpload = (data) => {
    dispatch("showSpinner");
    triggerAPI(
      {
        url: `community/collection/bulk-upload`,
        data,
        method: "post",
      },
      apiResult,
      (err) => {
        dispatch("hideSpinner");
        dispatch("showToast", {
          toast: {
            toastMode: "error",
            message:
              err?.response?.data?.error?.message || "Bulk uploading failed",
          },
        });
      }
    );
  };

  const submitCollectorsCollection = (data) => {
    dispatch("showSpinner");
    getCollection(data)
    onCloseCollectorCollectionSearch()
  };

  const onCloseBulkUpload = () => {
    setBulkUploadPopup({ mode: false });
  };

  const bulkUpload = () => {
    setBulkUploadPopup({ mode: true });
  };

  const onCloseCollectorCollectionSearch = () => {
    setCollectorCollectionSearchPopup({ mode: false });
  };

  const collectorCollectionSearch = () => {
    setCollectorCollectionSearchPopup({ mode: true });
  };


  const searchHandler = (e) => {
    onSearchChange(e)
  }
  const category =(data)=>{
    let category=data?.category?.name;
    if(data?.firstSubcategory?.name) {
      category=`${category} - ${data?.firstSubcategory?.name}`
    }
    return category??""
  }

  const getCategoryResponse = useCallback(
    (res) => {
      let updatedList=categorySort(res?.data?.list);
      setCategoryOptions(updatedList);
    },
    [dispatch]
  );
  const getCategoryList = useCallback(() => {
    triggerAPI(
      {
        url: `category/get`,
        data: {
          page: 1,
          items: 1000,
          filter: {},
          search: "",
        },
        method: "post",
      },
      getCategoryResponse
    );
  }, [dispatch, getCategoryResponse, triggerAPI]);

  
  const copytoClip = (e) => {
    const { id } = e.currentTarget.dataset;
    copyClipHandler(id, (res) => {
      res &&
        dispatch("showToast", {
          toast: { toastMode: "success", message: "Copied to clipboard" },
        });
    });
  };

  const handleClosePopup = () => {
    setOpenVerifyPopup(false)
    setOpenEditPopup(false)
    navigate(-1);
  };

  useEffect(()=>{
    getCategoryList()
    if(!!location?.search?.split('?')[1]){
      handleClosePopup()
    }
  },[])
  return (
    <>
      {openEditPopup && <ModalOuter openModal={openEditPopup}
        parentClass={openEditPopup ? '!z-[10]' : '!hidden !z-[10]'}
        >
        <EditCollection onClosePopup={handleClosePopup} onRefetchList={onRefetchCollectionList}/>
      </ModalOuter>}
      {openVerifyPopup && <ModalOuter openModal={openVerifyPopup}
        parentClass={openVerifyPopup ? '!z-[10]' : '!hidden !z-[10]'}
        >
       <EditCollection onClosePopup={handleClosePopup} isVerifyCollection onRefetchList={onRefetchCollectionList}/>
      </ModalOuter>}
      <div className="collection">
        <div className="flex justify-between items-center mb-4">
          
          <div className="search-filter flex gap-[1rem] items-center">
              <div>
                <SearchFilter
                  position={{ right: 150, top: 10 }}
                  data={filteredData}
                  onSearch={searchHandler}
                  // noSearch={true}
                  className="lengthy"
                  setActionMenu={setCollectionMenu}
                  actionMenu={collectionMenu}
                  onFilterData={onFilterData}
                  apply={true}
                />
              </div>  
      
              {!!(type === "collection")&&<MultiFilter 
                  image={filter} 
                  selectedValue={category} 
                  options={categoryOptions} 
                  onSelected={handleFilter}
                  blockchainFilterActive
                  className={'multi_filter-box-style'}
               />}
              
          </div>

          
                           


          {!!(type === "collection") && (
            <div>
              <Button label="Bulk Upload" onClick={bulkUpload} />
            </div>
          )}
           {!!(type === "collectorCollection") && (
            <div className="flex gap-2 items-center">
            <Button label="Export" onClick={collectorsCollectionsExport} />
            <Button label="Search" onClick={collectorCollectionSearch} />
          </div>
          )}
         
        </div>
        <div>
          <Table>
            <thead className="text-left">
              <tr>
                <th>
                  <TableHeader
                    label="Collection"
                    sort={sort}
                    onSort={onSortHandler}
                    sortKey="name"
                  />
                </th>
                {collectionMenu
                  ?.filter((el) => el?.selected)
                  ?.map((val) => (
                    <th key={val?.action}>
                      <TableHeader
                        label={val?.label}
                        sort={sort}
                        onSort={(key) => val?.sort && onSortHandler(key)}
                        sortKey={val?.sortKey ? val?.sortKey : ""}
                      />
                    </th>
                  ))}
                <th>
                  <TableHeader
                    label="Status"
                    sort={sort}
                    onSort={onSortHandler}
                    sortKey="status"
                  />
                </th>
                <th>
                  <div className="flex justify-end items-center">
                    <TableHeader label="Actions" />
                  </div>
                </th>
              </tr>
            </thead>
            <tbody className="gallery-body">
              {list?.map((value, index) => {
                let imagePreview = value?.collage || value?.filePath256;
                return (
                  <tr data-galleryid={value?.id} key={value.id}>
                    <td className="flex gap-3 items-center">
                      <div className="wall-image-gallery">
                        <Image
                          src={imagePreview ? imagePreview : ""}
                          className="gallery-image"
                          loaderClass="nft-drop-loader"
                          style={{ maxWidth: "4rem", Height: "4rem" }}
                        />
                      </div>
                      <div className="flex items-center gap-[1rem]">
                        <span className="name-span">{value?.name}</span>
                        <img
                          src={value?.blockchainIcon}
                          alt="blockchain icon"
                          className="w-[1.4rem]"
                        />
                      </div>
                      
                    </td>
                    {!!collectionMenu?.find((val) => val?.label === "Community")
                      ?.selected && <td>{value?.community?.name}</td>}
                    {!!collectionMenu?.find((val) => val?.label === "Wallet Address")
                      ?.selected && <td>{
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                    {value?.collectorWalletAddress &&
                    <p className="mt-1">
                      {value?.collectorWalletAddress?.length < 40
                        ? value?.collectorWalletAddress
                        : value?.collectorWalletAddress?.slice(0, 15) +
                        ".........." +
                        value?.collectorWalletAddress?.slice(-15)}
                    </p>
                    }
                    {value?.collectorWalletAddress && (
                      <img
                        onClick={copytoClip}
                        data-id={value?.collectorWalletAddress}
                        className="cursor-pointer w-4 ml-2"
                        alt="Copy Icon"
                        src={copyIcon}
                      />
                    )}
                  </div>
                    }
                    </td>}
                    {!!collectionMenu?.find((val) => val?.label === "Collector")
                      ?.selected && <td>{value?.collectorUsername}</td>}
                    {!!collectionMenu?.find((val) => val?.label === "Note")
                      ?.selected && <td>{value?.note}</td>}
                    {!!collectionMenu?.find((val) => val?.label === "Category")
                      ?.selected && <td>{category(value)}</td>}                    
                    {!!collectionMenu?.find((val) => val?.label === "Items")
                      ?.selected && <td>{value?.items}</td>}
                      {!!collectionMenu?.find((val) => val?.label === "Owners")
                      ?.selected && <td>{value?.metrics?.length ? (value?.metrics[0]?.owners ?? value?.ownerCount) : value?.ownerCount}</td>}
                       {!!collectionMenu?.find((val) => val?.label === "Collectors")
                      ?.selected && <td>{value?.collectorsCount}</td>}
                    
                    {!!collectionMenu?.find(
                      (val) => val?.label === "Collection IDs"
                    )?.selected && <td>{value?.collectionId}</td>}
                    {!!collectionMenu?.find(
                      (val) => val?.label === "posts"
                    )?.selected && <td>{value?.announcements}</td>}
                    {/* {!!collectionMenu?.find((val) => val?.label === "Upcoming Events")
                      ?.selected && <td>{value?.upcomingEventsCount}</td>} */}
                    {!!collectionMenu?.find(
                      (val) => val?.label === "Description"
                    )?.selected && <td>{value?.description}</td>}
                    {!!collectionMenu?.find((val) => val?.label === "About Us")
                      ?.selected && <td>{value?.community?.about}</td>}
                    {!!collectionMenu?.find(
                      (val) => val?.label === "Social Links"
                    )?.selected && (
                      <td>
                        <div className="flex gap-2">
                          {!!value?.website && (
                            <SocialMedia link={value?.website} type="website" />
                          )}
                          {!!value?.twitter && (
                            <SocialMedia link={value?.twitter} type="twitter" />
                          )}
                          {!!value?.discord && (
                            <SocialMedia link={value?.discord} type="discord" />
                          )}
                          {!!value?.instagram && (
                            <SocialMedia
                              link={value?.instagram}
                              type="instagram"
                            />
                          )}
                          {!!value?.youtube && (
                            <SocialMedia link={value?.youtube} type="youtube" />
                          )}
                          {!!value?.email && (
                            <SocialMedia link={value?.email} type="email" />
                          )}
                        </div>
                      </td>
                    )}
                    {!!collectionMenu?.find(
                      (val) => val?.label === "Blockchain"
                    )?.selected && <td>{value?.blockchainType?.name}</td>}
                    {!!collectionMenu?.find(
                      (val) => val?.label === "Featured"
                    )?.selected && <td>
                        <div className="flex gallery-home justify-center items-center" onClick={stopHandler}>
                            {
                            (value?.community && value?.status === 'active') && 
                            <ToggleSwitch className="gallery-switch" check={value?.showOnHomePageDisplay} row={value} onChange={toggleSwitch} />}
                        </div>
                      </td>}

                      {!!collectionMenu?.find(
                      (val) => val?.label === "Collaborative"
                    )?.selected && <td>
                        <div className="flex gallery-home justify-center items-center" onClick={stopHandler}>
                            {
                            <ToggleSwitch className="gallery-switch" check={value?.isCollaborative} row={value} onChange={toggleSwitchIsCollaborative} />}
                        </div>
                      </td>}

                      {!!collectionMenu?.find(
                      (val) => val?.label === "Open Source"
                    )?.selected && <td>
                        <div className="flex gallery-home justify-center items-center" onClick={stopHandler}>
                            {
                            <ToggleSwitch className="gallery-switch" check={value?.isOpenSource} row={value} onChange={toggleSwitchOpenSource} />}
                        </div>
                      </td>}


                    {!!collectionMenu?.find(
                      (val) => val?.label === "Created By"
                    )?.selected && (
                      <td>{value?.user?.username}</td>
                    )}
                    {!!collectionMenu?.find(
                      (val) => val?.label === "Reviewed By"
                    )?.selected && (
                      <td>{value?.reviewedUser}</td>
                    )}
                     {!!collectionMenu?.find(
                      (val) => val?.label === "Verified By"
                    )?.selected && (
                      <td>{value?.verifiedBy}</td>
                    )}
                    {!!collectionMenu?.find(
                      (val) => val?.label === "Blockchain IDs"
                    )?.selected && <td>{value?.blockchainTypeId}</td>}
                     {!!collectionMenu?.find(
                      (val) => val?.label === "Created At"
                    )?.selected && (
                      <td>{new Date(value?.createdAt)?.toLocaleString()}</td>
                    )}
                    <td>
                      <Status
                        label={
                          value?.status === "blocked" ? "Blocked" : (value?.status === "inactive" ? "Unreviewed" : (value?.status === "reviewed" ? "Reviewed" : (value?.status === "rejected" ? "Rejected" : (value?.status === "ownership_requested" ? "Ownership Requested" : "Verified"))))
                        }
                      />
                    </td>
                    <td>
                      <div
                        className="flex justify-end items-center"
                        onClick={stopHandler}
                      >
                        <ButtonAction
                          row={value}
                          src={ActionButton}
                          menu={
                            value?.status === "blocked" ? actionMenu
                              : value?.status === "inactive"
                              ? actionBlockActivateMenu
                              : value?.status === "rejected"
                              ? actionBlockActivateMenu
                              : (value?.status === "ownership_requested")
                              ? actionVerifyBlockMenu
                              : value?.status === "active"
                              ? activeMenu
                              : actionBlockMenu
                          }
                          onAction={actionHandler}
                        />
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          {!list?.length && (
            <div className="text-center no-rows pt-4">
              <p>No collections found!</p>
            </div>
          )}
          <div className="mt-6">
            <Pagination
              showPageInput={true}
              itemsPerPage={paginationConfig.perPage}
              total={totalPage}
              rows={list?.length}
              onPageChange={onPageChangeHandler}
              selected={page}
            />
          </div>
        </div>
      </div>
      <ConfirmPopup
        row={selectedData}
        show={showActivatePop}
        content={`Are you sure want to ${
          actionStatus === 'reviewed' ? 'mark as reviewed' : ((selectedData?.status === "blocked" ||
          selectedData?.status === "inactive") && actionStatus === 'activate')
            ? "activate"
            : selectedData?.status === "ownership_requested" ? "reject" 
            : actionStatus === "unlinkCreator" ? "revoke" : "block"
        } this collection?`}
        buttonYes="Yes"
        buttonCancel="No"
        style={{ maxWidth: "30rem" }}
        title={`${
          actionStatus === 'reviewed' ? 'Mark as Reviewed' : ((selectedData?.status === "blocked" ||
          selectedData?.status === "inactive") && actionStatus === 'activate')
            ? "Activate"
            : selectedData?.status === "ownership_requested" ? "Reject" 
            : actionStatus === "unlinkCreator" ? "Revoke" : "Block"
        } collection`}
        cssClass="delete-user"
        onClose={onCloseActivate}
      ></ConfirmPopup>
      <Popup
        show={showBulkUploadPopup}
        style={{ maxWidth: "40rem" }}
        title={`Bulk Upload`}
        cssClass="user-create-pop"
        onClose={onCloseBulkUpload}
      >
        <Upload triggerClose={onCloseBulkUpload} submit={submitBulkUpload} />
      </Popup>
      <Popup
        show={showCollectorCollectionSearchPopup}
        style={{ maxWidth: "50rem" }}
        title={`Search Collectors Collection`}
        cssClass="user-create-pop"
        onClose={onCloseCollectorCollectionSearch}
      >
        <CollectorCollectionSearch triggerClose={onCloseCollectorCollectionSearch} submit={submitCollectorsCollection} />
      </Popup>
    </>
  );
};

export default CollectionListing;
